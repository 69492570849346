/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  status: number;

  name: string;

  description?: string;

  payload?: {};

  userinfo?: string;
};
export type Season = {
  _id: string;

  appSpace: string;

  name: string;

  dateFrom: string;

  dateTo: string;

  sport: string;
};
export type AgeCategory = {
  _id: string;

  appSpace: string;

  name: string;

  gender?: string;

  maleAgeCategoryFrom?: string;

  femaleAgeCategoryFrom?: string;

  maleAgeCategoryTo?: string;

  femaleAgeCategoryTo?: string;
};
export type MatchManagerRole =
  | 'VAR'
  | 'NOMINATIONS'
  | 'PROTOCOL'
  | 'DATACOLLECTOR';
export type SportObject = {
  _id: string;

  appSpace: string;

  name: string;

  sport: string;

  street: string;

  number: string;

  owner: string;

  zip: string;

  city: string;

  country: string;

  sportGrounds?: Array<{
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  }>;
};
export type SportGround = {
  _id: string;

  name: string;

  displayName?: string;

  sportGroundType: string;

  additionalData?: {};
};
export type Team = {
  _id: string;

  appSpace: string;

  organization?: {
    _id: string;

    organization_id: string;

    organization_name?: string;

    name: string;

    country?: string;

    logo_public_url?: string;
  };

  logo?: {
    media_url: string;

    public_url: string;
  };

  smarttags?: Array<{
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  }>;

  name: string;

  displayName: string;

  shortName?: string;

  detailUrl?: string;

  gender?: string;

  sport_sector: string;

  filter?: {
    dateFrom?: string;

    dateTo?: string;
  };

  athletes?: Array<{
    sportnetUser: {
      _id: string;

      name: string;
    };

    additionalData?: {};
  }>;
};
export type Competition = {
  _id: string;

  competitionGroupId: string;

  homepageSectionIdOrUniqId?: string;

  appSpace: string;

  ownerPPO?: string;

  name: string;

  identifier?: string;

  note?: string;

  season: {
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  };

  sport: string;

  parts?: Array<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {};

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};

    dateFrom: string;

    dateTo: string;
  }>;

  signup?: boolean;

  aggregatedPlayerStats?: boolean;

  publication?: {};

  smarttags?: {
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  };

  smarttagsByAppSpace?: Array<{
    appSpace: string;

    smarttags: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };
  }>;

  teams?: Array<{
    _id: string;

    name: string;

    appSpace: string;
  }>;

  interstate?: boolean;
};
export type Match = {
  _id: string;

  appSpace: string;

  smarttags?: {
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  };

  perex?: string;

  smarttagsByAppSpace?: Array<{
    appSpace: string;

    smarttags: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };
  }>;

  teams: Array<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }>;

  television_broadcasters?: Array<{
    _id: string;

    label: string;
  }>;

  internet_broadcasters?: Array<{
    _id: string;

    label: string;
  }>;

  startDate: string;

  sportGround?: {
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  };

  competition: {
    _id: string;

    name: string;

    competitionGroupId: string;
  };

  competitionPart: {
    _id: string;

    name: string;
  };

  season: {
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  };

  round?: {
    _id?: string;

    name: string;

    dateFrom?: string;

    dateTo?: string;
  };

  nominations: Array<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }>;

  protocol?: {
    audience?: number;

    events?: Array<{}>;
  };

  rules?: {
    gender?: string;

    sport_sector?: string;

    discipline?: string;
  };

  closed?: boolean;

  contumation?: {
    isContumated: boolean;

    advantage: null | string;

    keepScore: boolean;
  };

  scoreComputationDisabled?: boolean;

  score?: Array<number>;

  penaltiesScore?: Array<number>;

  scoreByPhases?: Array<Array<number>>;

  livestream?: string;

  ticketsUrl?: string;

  detailUrl?: string;

  resultsTable?: {
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;

    players?: Array<{
      _id: string;

      teamId: string;

      name: string;

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;
  };

  settings: {
    players: {
      field: {
        min: number;

        max: number;
      };

      substitutes: {
        max: number;
      };
    };

    contumation: {
      score: number;
    };

    reverseTime: boolean;

    resultsTableDisabled?: boolean;

    overlapTime: boolean;

    phases: {};
  };

  liveState?: {
    phase: string;

    seconds: number;

    ongoing: boolean;

    toggleDate: string;
  };

  drawId?: number;

  timer?: {};

  managers?: Array<{
    user: {
      _id: string;

      name: string;
    };

    type: {
      label: string;

      value: string;
    };

    roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
  }>;
};
export type Settings_Base_domain = string;
export type Settings = {
  domain?: string;

  homepageSectionIdOrUniqId?: string;
};
export type Settings_Edit = {
  domain?: string;

  homepageSectionIdOrUniqId?: string;
};
export type Pager = {
  limit: number;

  offset: number;

  total: number;

  nextOffset?: number;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class CompetitionsApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class CompetitionsApi {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSeasons
   */
  adminGetSeasons(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateSeason
   */
  adminCreateSeason(
    appSpace: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  }> {
    let path = '/admin/{appSpace}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSeason
   */
  adminGetSeason(
    appSpace: string,
    seasonId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  }> {
    let path = '/admin/{appSpace}/seasons/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateSeason
   */
  adminUpdateSeason(
    appSpace: string,
    seasonId: string,
    parameters: {} = {},
    body: {
      name?: string;

      dateFrom?: string;

      dateTo?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  }> {
    let path = '/admin/{appSpace}/seasons/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteSeason
   */
  adminDeleteSeason(
    appSpace: string,
    seasonId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/seasons/{seasonId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{seasonId}', seasonId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetAgeCategories
   */
  adminGetAgeCategories(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    ageCategories: Array<{
      _id: string;

      appSpace: string;

      name: string;

      gender?: string;

      maleAgeCategoryFrom?: string;

      femaleAgeCategoryFrom?: string;

      maleAgeCategoryTo?: string;

      femaleAgeCategoryTo?: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/ageCategories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateAgeCategory
   */
  adminCreateAgeCategory(
    appSpace: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      name: string;

      gender?: string;

      maleAgeCategoryFrom?: string;

      femaleAgeCategoryFrom?: string;

      maleAgeCategoryTo?: string;

      femaleAgeCategoryTo?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    gender?: string;

    maleAgeCategoryFrom?: string;

    femaleAgeCategoryFrom?: string;

    maleAgeCategoryTo?: string;

    femaleAgeCategoryTo?: string;
  }> {
    let path = '/admin/{appSpace}/ageCategories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetAgeCategory
   */
  adminGetAgeCategory(
    appSpace: string,
    ageCategoryId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    gender?: string;

    maleAgeCategoryFrom?: string;

    femaleAgeCategoryFrom?: string;

    maleAgeCategoryTo?: string;

    femaleAgeCategoryTo?: string;
  }> {
    let path = '/admin/{appSpace}/ageCategories/{ageCategoryId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{ageCategoryId}', ageCategoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateAgeCategory
   */
  adminUpdateAgeCategory(
    appSpace: string,
    ageCategoryId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      name?: string;

      gender?: string;

      maleAgeCategoryFrom?: string;

      femaleAgeCategoryFrom?: string;

      maleAgeCategoryTo?: string;

      femaleAgeCategoryTo?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    gender?: string;

    maleAgeCategoryFrom?: string;

    femaleAgeCategoryFrom?: string;

    maleAgeCategoryTo?: string;

    femaleAgeCategoryTo?: string;
  }> {
    let path = '/admin/{appSpace}/ageCategories/{ageCategoryId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{ageCategoryId}', ageCategoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteAgeCategory
   */
  adminDeleteAgeCategory(
    appSpace: string,
    ageCategoryId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/ageCategories/{ageCategoryId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{ageCategoryId}', ageCategoryId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSuitableSeasons
   */
  adminGetSuitableSeasons(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/suitable-seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSportObjects
   */
  adminGetSportObjects(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    sportobjects: Array<{
      _id: string;

      appSpace: string;

      name: string;

      sport: string;

      street: string;

      number: string;

      owner: string;

      zip: string;

      city: string;

      country: string;

      sportGrounds?: Array<{
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/sport-objects';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateSportObject
   */
  adminCreateSportObject(
    appSpace: string,
    parameters: {} = {},
    body: {
      name: string;

      owner: string;

      sport: string;

      street: string;

      number: string;

      zip: string;

      city: string;

      country: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    sport: string;

    street: string;

    number: string;

    owner: string;

    zip: string;

    city: string;

    country: string;

    sportGrounds?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/sport-objects';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSportObject
   */
  adminGetSportObject(
    appSpace: string,
    sportobjectId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    sport: string;

    street: string;

    number: string;

    owner: string;

    zip: string;

    city: string;

    country: string;

    sportGrounds?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/sport-objects/{sportobjectId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateSportObject
   */
  adminUpdateSportObject(
    appSpace: string,
    sportobjectId: string,
    parameters: {} = {},
    body: {
      name?: string;

      owner?: string;

      street?: string;

      number?: string;

      zip?: string;

      city?: string;

      country?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    sport: string;

    street: string;

    number: string;

    owner: string;

    zip: string;

    city: string;

    country: string;

    sportGrounds?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/sport-objects/{sportobjectId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteSportObject
   */
  adminDeleteSportObject(
    appSpace: string,
    sportobjectId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/sport-objects/{sportobjectId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateSportGround
   */
  adminCreateSportGround(
    appSpace: string,
    sportobjectId: string,
    parameters: {} = {},
    body: {
      name?: string;

      sportGroundType?: string;

      additionalData?: {};
    },
  ): Promise<{
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  }> {
    let path = '/admin/{appSpace}/sport-objects/{sportobjectId}/sportGrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetSportGround
   */
  adminGetSportGround(
    appSpace: string,
    sportobjectId: string,
    sportGroundId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  }> {
    let path =
      '/admin/{appSpace}/sport-objects/{sportobjectId}/sportGrounds/{sportGroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    path = path.replace('{sportGroundId}', sportGroundId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateSportGround
   */
  adminUpdateSportGround(
    appSpace: string,
    sportobjectId: string,
    sportGroundId: string,
    parameters: {} = {},
    body: {
      name?: string;

      sportGroundType?: string;

      additionalData?: {};
    },
  ): Promise<{
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  }> {
    let path =
      '/admin/{appSpace}/sport-objects/{sportobjectId}/sportGrounds/{sportGroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportobjectId}', sportobjectId.toString());

    path = path.replace('{sportGroundId}', sportGroundId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetAllSportGrounds
   */
  adminGetAllSportGrounds(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/all-sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeams
   */
  adminGetTeams(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      seasonIds?: Array<string>;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seasonIds'] !== 'undefined') {
      queryParameters['season_ids'] = parameters['seasonIds'];
    }

    queryParameters['season_ids'] = this.transformParameter(
      queryParameters['season_ids'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateTeam
   */
  adminCreateTeam(
    appSpace: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      logo?: {
        media_url: string;

        public_url: string;
      };

      name: string;

      displayName: string;

      shortName?: string;

      gender?: string;

      sport_sector: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      detailUrl?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeam
   */
  adminGetTeam(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateTeam
   */
  adminUpdateTeam(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
    body: {
      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name?: string;

      displayName?: string;

      shortName?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      detailUrl?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteTeam
   */
  adminDeleteTeam(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamAthletes
   */
  adminGetTeamAthletes(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/athletes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamSquads
   */
  adminGetTeamSquads(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/squads';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateTeamSquads
   */
  adminCreateTeamSquads(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
    body: {
      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    },
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/squads';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamSquad
   */
  adminGetTeamSquad(
    appSpace: string,
    teamId: string,
    squadId: string,
    parameters: {} = {},
  ): Promise<{
    title: string;

    validFrom: string;

    crew: Array<{
      position?: string;

      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;

    athletes: Array<{
      additionalProperties?: {};

      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/squads/{squadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{squadId}', squadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteTeamSquad
   */
  adminDeleteTeamSquad(
    appSpace: string,
    teamId: string,
    squadId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/teams/{teamId}/squads/{squadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{squadId}', squadId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateTeamSquad
   */
  adminUpdateTeamSquad(
    appSpace: string,
    teamId: string,
    squadId: string,
    parameters: {} = {},
    body: {
      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    },
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/squads/{squadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{squadId}', squadId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamMembersList
   */
  adminGetTeamMembersList(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/members';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateTeamMembers
   */
  adminCreateTeamMembers(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
    body: {
      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    },
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/members';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamMembers
   */
  adminGetTeamMembers(
    appSpace: string,
    teamId: string,
    membersId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    title: string;

    validFrom: string;

    crew: Array<{
      position: string;

      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/members/{membersId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{membersId}', membersId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteTeamMembers
   */
  adminDeleteTeamMembers(
    appSpace: string,
    teamId: string,
    membersId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/teams/{teamId}/members/{membersId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{membersId}', membersId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateTeamMembers
   */
  adminUpdateTeamMembers(
    appSpace: string,
    teamId: string,
    membersId: string,
    parameters: {} = {},
    body: {
      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    },
  ): Promise<{
    items: Array<{
      _id?: string;

      title: string;

      validFrom: string;

      crew: Array<{
        position: string;

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/members/{membersId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{membersId}', membersId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetTeamCompetitions
   */
  adminGetTeamCompetitions(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    competitions: Array<{
      _id: string;

      competitionGroupId: string;

      homepageSectionIdOrUniqId?: string;

      appSpace: string;

      ownerPPO?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      parts?: Array<{
        _id: string;

        name: string;

        rounds?: Array<{
          _id: string;

          name: string;

          dateFrom: string;

          dateTo: string;
        }>;

        rules?: {};

        settings?: {};

        signup?: boolean;

        resultsTable?: {
          results: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;

              organization?: {
                _id: string;

                name: string;

                organization_id: string;

                organization_name: string;

                country?: string;
              };

              resignation?: boolean;
            };

            stats: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            homeStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            awayStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            matches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            homeMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            awayMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;
          }>;

          players?: Array<{
            _id: string;

            teamId: string;

            name: string;

            stats: {
              match_starts?: number;

              match_appearances?: number;

              goals?: number;

              assists?: number;

              yellow_cards?: number;

              red_cards?: number;

              substitutions?: number;

              own_goals?: number;
            };
          }>;
        };

        matches_count?: number;

        matchesCountByTeamId?: {};

        dateFrom: string;

        dateTo: string;
      }>;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    }>;
  }> {
    let path = '/admin/{appSpace}/teams/{teamId}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitions
   */
  adminGetCompetitions(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      seasonIds?: Array<string>;
      q?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    competitions: Array<{
      _id: string;

      competitionGroupId: string;

      homepageSectionIdOrUniqId?: string;

      appSpace: string;

      ownerPPO?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      parts?: Array<{
        _id: string;

        name: string;

        rounds?: Array<{
          _id: string;

          name: string;

          dateFrom: string;

          dateTo: string;
        }>;

        rules?: {};

        settings?: {};

        signup?: boolean;

        resultsTable?: {
          results: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;

              organization?: {
                _id: string;

                name: string;

                organization_id: string;

                organization_name: string;

                country?: string;
              };

              resignation?: boolean;
            };

            stats: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            homeStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            awayStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            matches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            homeMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            awayMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;
          }>;

          players?: Array<{
            _id: string;

            teamId: string;

            name: string;

            stats: {
              match_starts?: number;

              match_appearances?: number;

              goals?: number;

              assists?: number;

              yellow_cards?: number;

              red_cards?: number;

              substitutions?: number;

              own_goals?: number;
            };
          }>;
        };

        matches_count?: number;

        matchesCountByTeamId?: {};

        dateFrom: string;

        dateTo: string;
      }>;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    }>;
  }> {
    let path = '/admin/{appSpace}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seasonIds'] !== 'undefined') {
      queryParameters['season_ids'] = parameters['seasonIds'];
    }

    queryParameters['season_ids'] = this.transformParameter(
      queryParameters['season_ids'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateCompetition
   */
  adminCreateCompetition(
    appSpace: string,
    parameters: {} = {},
    body: {
      competitionGroupId?: string;

      homepageSectionIdOrUniqId?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    },
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path = '/admin/{appSpace}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionsByCompetitionGroup
   */
  adminGetCompetitionsByCompetitionGroup(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    competitions: Array<{
      _id: string;

      competitionGroupId: string;

      homepageSectionIdOrUniqId?: string;

      appSpace: string;

      ownerPPO?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      parts?: Array<{
        _id: string;

        name: string;

        rounds?: Array<{
          _id: string;

          name: string;

          dateFrom: string;

          dateTo: string;
        }>;

        rules?: {};

        settings?: {};

        signup?: boolean;

        resultsTable?: {
          results: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;

              organization?: {
                _id: string;

                name: string;

                organization_id: string;

                organization_name: string;

                country?: string;
              };

              resignation?: boolean;
            };

            stats: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            homeStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            awayStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            matches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            homeMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            awayMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;
          }>;

          players?: Array<{
            _id: string;

            teamId: string;

            name: string;

            stats: {
              match_starts?: number;

              match_appearances?: number;

              goals?: number;

              assists?: number;

              yellow_cards?: number;

              red_cards?: number;

              substitutions?: number;

              own_goals?: number;
            };
          }>;
        };

        matches_count?: number;

        matchesCountByTeamId?: {};

        dateFrom: string;

        dateTo: string;
      }>;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    }>;
  }> {
    let path = '/admin/{appSpace}/competitions-by-group';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetition
   */
  adminGetCompetition(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateCompetition
   */
  adminUpdateCompetition(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
    body: {
      aggregatedPlayerStats?: boolean;

      name?: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      season?: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      homepageSectionIdOrUniqId?: string;

      identifier?: string;

      note?: string;

      sport?: string;

      signup?: boolean;

      publication?: {
        logo?: {
          filepath?: string;

          media_url?: string;

          public_url?: string;
        };

        published?: boolean;
      };

      interstate?: boolean;
    },
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteCompetition
   */
  adminDeleteCompetition(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#regenerateAllResults
   */
  regenerateAllResults(
    parameters: {
      seasonName?: string;
      appSpace?: string;
      ownerPpo?: string;
    } = {},
  ): Promise<{}> {
    let path = '/app/regenerate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonName'] !== 'undefined') {
      queryParameters['seasonName'] = parameters['seasonName'];
    }

    queryParameters['seasonName'] = this.transformParameter(
      queryParameters['seasonName'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['ownerPpo'] !== 'undefined') {
      queryParameters['ownerPPO'] = parameters['ownerPpo'];
    }

    queryParameters['ownerPPO'] = this.transformParameter(
      queryParameters['ownerPPO'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#regenerateCompetitionPartMatches
   */
  regenerateCompetitionPartMatches(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path =
      '/app/regenerate/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#appDeleteCompetition
   */
  appDeleteCompetition(
    competitionId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/app/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#calculateCompetitionPartResultTable
   */
  calculateCompetitionPartResultTable(
    competitionId: string,
    partId: string,
    parameters: {
      regenerateMatches?: boolean;
    } = {},
    body: {
      type:
        | 'BEZ_PREPOCTU'
        | 'S_PREPOCTOM_VZAJOMNYCH_ZAPASOV'
        | 'S_PREPOCTOM'
        | 'S_PREPOCTOM_PLUS_BONUSOVE';

      sourcePartIds: Array<string>;
    },
  ): Promise<{}> {
    let path =
      '/app/competitions/{competitionId}/parts/{partId}/generate-table';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['regenerateMatches'] !== 'undefined') {
      queryParameters['regenerateMatches'] = parameters['regenerateMatches'];
    }

    queryParameters['regenerateMatches'] = this.transformParameter(
      queryParameters['regenerateMatches'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCalculateCompetitionPartResultTable
   */
  adminCalculateCompetitionPartResultTable(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {
      regenerateMatches?: boolean;
    } = {},
    body: {
      type:
        | 'BEZ_PREPOCTU'
        | 'S_PREPOCTOM_VZAJOMNYCH_ZAPASOV'
        | 'S_PREPOCTOM'
        | 'S_PREPOCTOM_PLUS_BONUSOVE';

      sourcePartIds: Array<string>;
    },
  ): Promise<{}> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/generate-table';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['regenerateMatches'] !== 'undefined') {
      queryParameters['regenerateMatches'] = parameters['regenerateMatches'];
    }

    queryParameters['regenerateMatches'] = this.transformParameter(
      queryParameters['regenerateMatches'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionSuitableTeams
   */
  adminGetCompetitionSuitableTeams(
    appSpace: string,
    competitionId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/suitable-teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionPartTeamSquad
   */
  adminGetCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    type?: string;

    sport_sector: string;

    squad?: {
      athletes?: Array<{
        additionalProperties?: {};

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminAddPlayerToCompetitionPartTeamSquad
   */
  adminAddPlayerToCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      citizenship?: string | null;

      additionalData?: {};
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminAddPlayersToCompetitionPartTeamSquad
   */
  adminAddPlayersToCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      players: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminEditPlayerInCompetitionPartTeamSquad
   */
  adminEditPlayerInCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      additionalData?: {};
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminRemovePlayerFromCompetitionPartTeamSquad
   */
  adminRemovePlayerFromCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminAddCrewMemberToCompetitionPartTeamSquad
   */
  adminAddCrewMemberToCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      position?: string;
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminEditCrewMemberInCompetitionPartTeamSquad
   */
  adminEditCrewMemberInCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      position?: string;
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminRemoveCrewMemberFromCompetitionPartTeamSquad
   */
  adminRemoveCrewMemberFromCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminInvitePlayerToCompetitionPartTeamSquad
   */
  adminInvitePlayerToCompetitionPartTeamSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      email: string;
    },
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/invite/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionParts
   */
  adminGetCompetitionParts(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    parts?: Array<{
      _id: string;

      name: string;

      format?: 'points' | 'draw';

      rules?: {
        sport_sector: string;

        discipline?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      dateFrom: string;

      dateTo: string;

      settings?: {};

      signup?: boolean;

      matches_count?: number;

      matchesCountByTeamId?: {};
    }>;
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateCompetitionPart
   */
  adminCreateCompetitionPart(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
    body: {
      name: string;

      type?: string;

      format?: 'points' | 'draw';

      rules?: {
        sport_sector?: string;

        discipline?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;

        loosePlayerSignup?: boolean;
      };

      dateFrom: string;

      dateTo: string;

      settings?: {};

      signup?: boolean;
    },
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {
      sport_sector: string;

      discipline?: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionPartById
   */
  adminGetCompetitionPartById(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {
      sport_sector: string;

      discipline?: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateCompetitionPart
   */
  adminUpdateCompetitionPart(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      name?: string;

      rules?: {
        sport_sector: string;

        discipline?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      dateFrom?: string;

      dateTo?: string;

      settings?: {};

      resultsTable?: {};
    },
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {
      sport_sector: string;

      discipline?: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteCompetitionPart
   */
  adminDeleteCompetitionPart(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminAssignClubManager
   */
  adminAssignClubManager(
    appSpace: string,
    competitionId: string,
    partId: string,
    clubId: string,
    parameters: {} = {},
    body: {
      sportnetId: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/clubs/{clubId}/clubManagers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminRemoveClubManager
   */
  adminRemoveClubManager(
    appSpace: string,
    competitionId: string,
    partId: string,
    clubId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/clubs/{clubId}/clubManagers/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionPartRounds
   */
  adminGetCompetitionPartRounds(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    rounds: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/rounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateCompetitionPartRound
   */
  adminCreateCompetitionPartRound(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      name: string;

      dateFrom: string;

      dateTo: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    dateFrom: string;

    dateTo: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/rounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetOrganizationAthletes
   */
  adminGetOrganizationAthletes(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {
      teamId: string;
      q?: string;
      competenceType?: string;
    },
  ): Promise<{
    athletes?: Array<{
      sportnetId: string;

      displayName: string;

      familyName?: string;

      birthyear?: string;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/suitable-athletes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['team_id'] = parameters['teamId'];
    }

    queryParameters['team_id'] = this.transformParameter(
      queryParameters['team_id'],
    );

    if (typeof parameters['teamId'] === 'undefined') {
      throw new Error('Missing required parameter: teamId');
    }

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['competenceType'] !== 'undefined') {
      queryParameters['competenceType'] = parameters['competenceType'];
    }

    queryParameters['competenceType'] = this.transformParameter(
      queryParameters['competenceType'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetCompetitionPartSuitableTeams
   */
  adminGetCompetitionPartSuitableTeams(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {
      limit?: number;
      offset?: number;
      q?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/suitable-teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminSignupCompetitionPartTeam
   */
  adminSignupCompetitionPartTeam(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      teamId?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateCompetitionPartSignedUpTeam
   */
  adminUpdateCompetitionPartSignedUpTeam(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      logo?: {
        public_url: string;

        media_url: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminRemoveCompetitionPartSignedUpTeam
   */
  adminRemoveCompetitionPartSignedUpTeam(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#externalRegistrationIntoSquad
   */
  externalRegistrationIntoSquad(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      name: string;

      surname: string;

      birthdate?: string | null;

      type: 'sport_org' | 'sport_expert_org';

      position?: string;

      competenceType?: string;

      dateFrom?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/external-registration';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminInviteCompetitionPartClub
   */
  adminInviteCompetitionPartClub(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      club: string;

      email: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/clubs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionPartCurrentResultTable
   */
  getCompetitionPartCurrentResultTable(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;

    players?: Array<{
      _id: string;

      teamId: string;

      name: string;

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/results';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetMatches
   */
  adminGetMatches(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      dateFrom?: string;
      dateTo?: string;
      closed?: string;
      seasonIds?: Array<string>;
      competitionIds?: Array<string>;
      partIds?: Array<string>;
      roundIds?: Array<string>;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['seasonIds'] !== 'undefined') {
      queryParameters['season_ids'] = parameters['seasonIds'];
    }

    queryParameters['season_ids'] = this.transformParameter(
      queryParameters['season_ids'],
    );

    if (typeof parameters['competitionIds'] !== 'undefined') {
      queryParameters['competition_ids'] = parameters['competitionIds'];
    }

    queryParameters['competition_ids'] = this.transformParameter(
      queryParameters['competition_ids'],
    );

    if (typeof parameters['partIds'] !== 'undefined') {
      queryParameters['part_ids'] = parameters['partIds'];
    }

    queryParameters['part_ids'] = this.transformParameter(
      queryParameters['part_ids'],
    );

    if (typeof parameters['roundIds'] !== 'undefined') {
      queryParameters['round_ids'] = parameters['roundIds'];
    }

    queryParameters['round_ids'] = this.transformParameter(
      queryParameters['round_ids'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateMatch
   */
  adminCreateMatch(
    appSpace: string,
    parameters: {} = {},
    body: {
      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      livestream?: string;

      ticketsUrl?: string;

      detailUrl?: string;

      perex?: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      competitionId: string;

      partId: string;

      seasonId?: string;

      roundId?: string;

      drawId?: number;

      closed?: boolean;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetMatchById
   */
  adminGetMatchById(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateMatch
   */
  adminUpdateMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      scoreComputationDisabled?: boolean;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      score?: Array<number>;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      livestream?: string;

      ticketsUrl?: string;

      detailUrl?: string;

      perex?: string;

      teams?: Array<{
        _id?: string;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      competitionId: string;

      partId: string;

      seasonId?: string;

      roundId?: string;

      nominations?: Array<{
        teamId?: string;

        athletes: Array<{
          sportnetUser: {
            _id: string | null;

            name: string;
          };

          additionalData?: {};
        }>;

        crew?: Array<{
          sportnetUser: {
            _id: string | null;

            name: string | null;
          };

          position: string;
        }>;

        closed?: boolean;
      }>;

      protocol?: {};

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      closed?: boolean;

      drawId?: number;

      timer?: {};
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminDeleteMatch
   */
  adminDeleteMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getMatchProtocolPDF
   */
  getMatchProtocolPDF(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    url: string;

    downloadId: string;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/pdf';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#sendMatchProtocolPDF
   */
  sendMatchProtocolPDF(
    appSpace: string,
    matchId: string,
    parameters: {
      email: string;
    },
  ): Promise<{
    message?: string;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/pdf/send';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['email'] === 'undefined') {
      throw new Error('Missing required parameter: email');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#appDeleteMatch
   */
  appDeleteMatch(
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/app/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#appGetScore
   */
  appGetScore(
    parameters: {} = {},
    body: {
      settings?: {
        phases?: {};

        contumation?: {};
      };

      timer?: object | null;

      teams?: {
        _id?: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      };

      protocol?: {
        events?: Array<{}>;
      };

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };
    },
  ): Promise<{
    contumationScore?: Array<number>;

    score?: Array<number>;

    scoreIsComputed?: boolean;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;
  }> {
    let path = '/app/match-score';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#appAddMatchManager
   */
  appAddMatchManager(
    matchId: string,
    parameters: {} = {},
    body: {
      user: {
        _id: string;

        name: string;

        citizenship?: string | null;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/app/matches/{matchId}/managers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminAddMatchManager
   */
  adminAddMatchManager(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      user: {
        _id: string;

        name: string;

        citizenship?: string | null;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/managers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminRemoveMatchManager
   */
  adminRemoveMatchManager(
    appSpace: string,
    matchId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/managers/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateMatchContumation
   */
  adminUpdateMatchContumation(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/contumation';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetMatchSuitableTeams
   */
  adminGetMatchSuitableTeams(
    appSpace: string,
    matchId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/suitable-teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetMatchNominations
   */
  adminGetMatchNominations(
    appSpace: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminPutMatchNominations
   */
  adminPutMatchNominations(
    appSpace: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    },
  ): Promise<{}> {
    let path = '/admin/{appSpace}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#externalRegistrationIntoNomination
   */
  externalRegistrationIntoNomination(
    appSpace: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      name?: string;

      surname?: string;

      birthdate?: string | null;

      competenceType?: string;

      dateFrom?: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/matches/{matchId}/nominations/{teamId}/external-registration';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#externalRegistrationDelegate
   */
  externalRegistrationDelegate(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      name?: string;

      surname?: string;

      birthdate?: string | null;

      competenceType?: string;

      dateFrom?: string;
    },
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/matches/{matchId}/delegates-external-registration';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetMatchProtocol
   */
  adminGetMatchProtocol(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/matches/{matchId}/protocol';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateMatchLiveState
   */
  adminUpdateMatchLiveState(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      liveState: {
        phase: string;

        seconds: number;

        ongoing: boolean;

        toggleDate: string;
      };

      timer?: {};
    },
  ): Promise<{
    phase: string;

    seconds: number;

    ongoing: boolean;

    toggleDate: string;
  }> {
    let path = '/admin/{appSpace}/matches/{matchId}/liveState';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#regenerateMatchCompetitionPartResults
   */
  regenerateMatchCompetitionPartResults(
    appSpace: string,
    matchId: string,
    parameters: {
      regenerateAll?: boolean;
    } = {},
  ): Promise<{}> {
    let path = '/admin/{appSpace}/matches/{matchId}/regenerate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    if (typeof parameters['regenerateAll'] !== 'undefined') {
      queryParameters['regenerateAll'] = parameters['regenerateAll'];
    }

    queryParameters['regenerateAll'] = this.transformParameter(
      queryParameters['regenerateAll'],
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetOrganizationSportExperts
   */
  adminGetOrganizationSportExperts(
    appSpace: string,
    parameters: {
      q?: string;
      ppo?: string;
      competenceTypes?: Array<string>;
      teamId?: string;
    } = {},
  ): Promise<{
    sportExperts?: Array<{
      sportnetId: string;

      displayName: string;

      familyName?: string;

      birthyear?: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/sport-experts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ppo'] !== 'undefined') {
      queryParameters['ppo'] = parameters['ppo'];
    }

    queryParameters['ppo'] = this.transformParameter(queryParameters['ppo']);

    if (typeof parameters['competenceTypes'] !== 'undefined') {
      queryParameters['competenceTypes'] = parameters['competenceTypes'];
    }

    queryParameters['competenceTypes'] = this.transformParameter(
      queryParameters['competenceTypes'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['team_id'] = parameters['teamId'];
    }

    queryParameters['team_id'] = this.transformParameter(
      queryParameters['team_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetRecords
   */
  adminGetRecords(
    appSpace: string,
    parameters: {
      gender?: string;
      ageCategoryId?: string;
      disciplineName?: string;
      disciplineParameters?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      gender: string;

      sport: string;

      records: Array<{
        place: string;

        date: string;

        record: {
          type: string;

          value: string;
        };

        holders: Array<{
          _id: string;

          name: string;
        }>;

        club: {
          _id: string;

          name: string;
        };
      }>;

      ageCategory: {
        _id: string;

        name: string;
      };

      discipline: {
        _id: string;

        name: string;

        parameters: Array<{
          _id: string;

          name: string;

          value: string;
        }>;
      };
    }>;
  }> {
    let path = '/admin/{appSpace}/records';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['gender'] !== 'undefined') {
      queryParameters['gender'] = parameters['gender'];
    }

    queryParameters['gender'] = this.transformParameter(
      queryParameters['gender'],
    );

    if (typeof parameters['ageCategoryId'] !== 'undefined') {
      queryParameters['ageCategoryId'] = parameters['ageCategoryId'];
    }

    queryParameters['ageCategoryId'] = this.transformParameter(
      queryParameters['ageCategoryId'],
    );

    if (typeof parameters['disciplineName'] !== 'undefined') {
      queryParameters['disciplineName'] = parameters['disciplineName'];
    }

    queryParameters['disciplineName'] = this.transformParameter(
      queryParameters['disciplineName'],
    );

    if (typeof parameters['disciplineParameters'] !== 'undefined') {
      queryParameters['disciplineParameters'] =
        parameters['disciplineParameters'];
    }

    queryParameters['disciplineParameters'] = this.transformParameter(
      queryParameters['disciplineParameters'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminCreateRecord
   */
  adminCreateRecord(
    appSpace: string,
    parameters: {} = {},
    body: {
      sport: string;

      gender: string;

      ageCategory: {
        _id: string;

        name: string;
      };

      discipline: {
        _id: string;

        name: string;

        parameters: Array<{
          _id: string;

          name: string;

          value: string;
        }>;
      };

      records: Array<{
        place: string;

        date: string;

        record: {
          type: string;

          value: string;
        };

        holders: Array<{
          _id: string;

          name: string;
        }>;

        club: {
          _id: string;

          name: string;
        };
      }>;
    },
  ): Promise<{
    _id: string;

    gender: string;

    sport: string;

    records: Array<{
      place: string;

      date: string;

      record: {
        type: string;

        value: string;
      };

      holders: Array<{
        _id: string;

        name: string;
      }>;

      club: {
        _id: string;

        name: string;
      };
    }>;

    ageCategory: {
      _id: string;

      name: string;
    };

    discipline: {
      _id: string;

      name: string;

      parameters: Array<{
        _id: string;

        name: string;

        value: string;
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/records';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetRecordById
   */
  adminGetRecordById(
    appSpace: string,
    recordId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    gender: string;

    sport: string;

    records: Array<{
      place: string;

      date: string;

      record: {
        type: string;

        value: string;
      };

      holders: Array<{
        _id: string;

        name: string;
      }>;

      club: {
        _id: string;

        name: string;
      };
    }>;

    ageCategory: {
      _id: string;

      name: string;
    };

    discipline: {
      _id: string;

      name: string;

      parameters: Array<{
        _id: string;

        name: string;

        value: string;
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/records/{recordId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{recordId}', recordId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateRecord
   */
  adminUpdateRecord(
    appSpace: string,
    recordId: string,
    parameters: {} = {},
    body: {
      records: Array<{
        place: string;

        date: string;

        record: {
          type: string;

          value: string | number;
        };

        holders: Array<{
          _id: string | null;

          name: string;
        }>;

        club: {
          _id: string | null;

          name: string;
        };
      }>;
    },
  ): Promise<{
    _id: string;

    gender: string;

    sport: string;

    records: Array<{
      place: string;

      date: string;

      record: {
        type: string;

        value: string;
      };

      holders: Array<{
        _id: string;

        name: string;
      }>;

      club: {
        _id: string;

        name: string;
      };
    }>;

    ageCategory: {
      _id: string;

      name: string;
    };

    discipline: {
      _id: string;

      name: string;

      parameters: Array<{
        _id: string;

        name: string;

        value: string;
      }>;
    };
  }> {
    let path = '/admin/{appSpace}/records/{recordId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{recordId}', recordId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminGetRecordsAgeCategories
   */
  adminGetRecordsAgeCategories(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string | null;

      name: string;
    }>;
  }> {
    let path = '/admin/{appSpace}/records-agecategories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @name CompetitionsApi#adminGetSettings
   */
  adminGetSettings(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    domain?: string;

    homepageSectionIdOrUniqId?: string;
  }> {
    let path = '/admin/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Edit Contentspace Settings
   * @method
   * @name CompetitionsApi#adminEditSettings
   */
  adminEditSettings(
    appSpace: string,
    parameters: {} = {},
    body: {
      domain?: string;

      homepageSectionIdOrUniqId?: string;
    },
  ): Promise<{
    domain?: string;

    homepageSectionIdOrUniqId?: string;
  }> {
    let path = '/admin/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#notifyMatchChange
   */
  notifyMatchChange(
    matchId: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/competitions/{competitionId}/matches/{matchId}/notify-change';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#adminUpdateTeamPlayerData
   */
  adminUpdateTeamPlayerData(
    appSpace: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
    body: {
      sportnetUser: {
        name: string;

        surname: string;

        citizenship?: string;

        titles?: {
          before?: string;

          after?: string;
        };
      };

      competitions: Array<string>;
    },
  ): Promise<{}> {
    let path = '/admin/{appSpace}/teams/{teamId}/player/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getClubInvitations
   */
  getClubInvitations(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    invitations: Array<{
      _id: string;

      appId: string;

      createdDT: string;

      ppo: string;

      title: string;

      description: string;

      email: string;

      callback: string;

      expiration: string | null;

      payload?: {};

      status?: string;

      sportnetId?: string;

      resolved?: {
        dt?: string;

        ip?: string | null;

        ips?: Array<string | null> | null;

        userAgent?: string | null;
      };

      pending?: {
        status?: string;

        sportnetId?: string;
      };

      exitUrl?: {
        accepted?: string;

        rejected?: string;
      };

      senderUser?: {
        sportnetId?: string;

        displayName?: string;
      };
    }>;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/club-invitations';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#processClubInvitationResponse
   */
  processClubInvitationResponse(
    appSpace: string,
    competitionId: string,
    partId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/club-invitations/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#processPlayerInvitationResponse
   */
  processPlayerInvitationResponse(
    appSpace: string,
    competitionId: string,
    partId: string,
    invitationId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path =
      '/admin/{appSpace}/competitions/{competitionId}/parts/{partId}/player-invitations/{invitationId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{invitationId}', invitationId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetCompetitionParts
   */
  clubManagerGetCompetitionParts(
    parameters: {
      dateTo?: string;
      appSpace?: string;
    } = {},
  ): Promise<{
    parts: Array<{
      _id: string;

      name: string;

      competition: {
        _id: string;

        name: string;
      };

      dateFrom: string;

      dateTo: string;

      appSpace: string;

      teamNominated: {
        _id: string;

        name: string;
      };

      squadNominated: boolean;
    }>;
  }> {
    let path = '/club-manager/parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetCompetition
   */
  clubManagerGetCompetition(
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path = '/club-manager/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetCompetitionPartById
   */
  clubManagerGetCompetitionPartById(
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {
      sport_sector: string;

      discipline?: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;
  }> {
    let path = '/club-manager/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerUpdateCompetitionSmarttags
   */
  clubManagerUpdateCompetitionSmarttags(
    competitionId: string,
    partId: string,
    clubId: string,
    parameters: {} = {},
    body: {
      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    },
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/smarttags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetCompetitionPartEligibleTeams
   */
  clubManagerGetCompetitionPartEligibleTeams(
    competitionId: string,
    partId: string,
    clubId: string,
    parameters: {} = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    items: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerSignupCompetitionPartTeam
   */
  clubManagerSignupCompetitionPartTeam(
    competitionId: string,
    partId: string,
    clubId: string,
    parameters: {} = {},
    body: {
      teamId?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerCreateTeam
   */
  clubManagerCreateTeam(
    competitionId: string,
    partId: string,
    clubId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      logo?: {
        media_url: string;

        public_url: string;
      };

      name: string;

      displayName: string;

      shortName?: string;

      gender?: string;

      sport_sector: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      detailUrl?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/team';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{clubId}', clubId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetOrganizationAthletes
   */
  clubManagerGetOrganizationAthletes(
    clubId: string,
    competitionId: string,
    partId: string,
    parameters: {
      teamId: string;
      q?: string;
      competenceType?: string;
    },
  ): Promise<{
    athletes?: Array<{
      sportnetId: string;

      displayName: string;

      familyName?: string;

      birthyear?: string;
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/suitable-athletes';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{clubId}', clubId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['team_id'] = parameters['teamId'];
    }

    queryParameters['team_id'] = this.transformParameter(
      queryParameters['team_id'],
    );

    if (typeof parameters['teamId'] === 'undefined') {
      throw new Error('Missing required parameter: teamId');
    }

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['competenceType'] !== 'undefined') {
      queryParameters['competenceType'] = parameters['competenceType'];
    }

    queryParameters['competenceType'] = this.transformParameter(
      queryParameters['competenceType'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetCompetitionPartTeamSquad
   */
  clubManagerGetCompetitionPartTeamSquad(
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    type?: string;

    sport_sector: string;

    squad?: {
      athletes?: Array<{
        additionalProperties?: {};

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerAddPlayerToCompetitionPartTeamSquad
   */
  clubManagerAddPlayerToCompetitionPartTeamSquad(
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      additionalData?: {};
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerEditPlayerInCompetitionPartTeamSquad
   */
  clubManagerEditPlayerInCompetitionPartTeamSquad(
    clubId: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
    body: {
      additionalData?: {};
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{clubId}', clubId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerRemovePlayerFromCompetitionPartTeamSquad
   */
  clubManagerRemovePlayerFromCompetitionPartTeamSquad(
    clubId: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{clubId}', clubId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerInvitePlayerToCompetitionPartTeamSquad
   */
  clubManagerInvitePlayerToCompetitionPartTeamSquad(
    clubId: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      email: string;
    },
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/club/{clubId}/teams/{teamId}/invite/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{clubId}', clubId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerAddCrewMemberToCompetitionPartTeamSquad
   */
  clubManagerAddCrewMemberToCompetitionPartTeamSquad(
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      position?: string;
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerEditCrewMemberInCompetitionPartTeamSquad
   */
  clubManagerEditCrewMemberInCompetitionPartTeamSquad(
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
    body: {
      position?: string;
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerRemoveCrewMemberFromCompetitionPartTeamSquad
   */
  clubManagerRemoveCrewMemberFromCompetitionPartTeamSquad(
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/crew/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetMatches
   */
  clubManagerGetMatches(
    parameters: {
      limit?: number;
      offset?: number;
      dateFrom?: string;
      dateTo?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;

      managedTeamId: string;

      nominationStatus: 'EMPTY' | 'FILLED' | 'CLOSED';
    }>;
  }> {
    let path = '/club-manager/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetMatchById
   */
  clubManagerGetMatchById(
    competitionId: string,
    partId: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerUpdateMatchSmarttags
   */
  clubManagerUpdateMatchSmarttags(
    competitionId: string,
    partId: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/team/{teamId}/smarttags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetMatchNominations
   */
  clubManagerGetMatchNominations(
    competitionId: string,
    partId: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerPutMatchNominations
   */
  clubManagerPutMatchNominations(
    competitionId: string,
    partId: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    },
  ): Promise<{}> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#clubManagerGetOrganizationSportExperts
   */
  clubManagerGetOrganizationSportExperts(
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {
      q?: string;
      competenceTypes?: Array<string>;
    } = {},
  ): Promise<{
    sportExperts?: Array<{
      sportnetId: string;

      displayName: string;

      familyName?: string;

      birthyear?: string;
    }>;
  }> {
    let path =
      '/club-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/sport-experts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['competenceTypes'] !== 'undefined') {
      queryParameters['competenceTypes'] = parameters['competenceTypes'];
    }

    queryParameters['competenceTypes'] = this.transformParameter(
      queryParameters['competenceTypes'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetMatches
   */
  matchManagerGetMatches(
    parameters: {
      limit?: number;
      offset?: number;
      dateFrom?: string;
      dateTo?: string;
      closed?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;
  }> {
    let path = '/match-manager/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetCompetition
   */
  matchManagerGetCompetition(
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    identifier?: string;

    note?: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    sport: string;

    parts?: Array<{
      _id: string;

      name: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;

      rules?: {};

      settings?: {};

      signup?: boolean;

      resultsTable?: {
        results: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;

            organization?: {
              _id: string;

              name: string;

              organization_id: string;

              organization_name: string;

              country?: string;
            };

            resignation?: boolean;
          };

          stats: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          homeStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          awayStats?: {
            matches: {
              played: number;

              won: number;

              draw: number;

              lost: number;
            };

            goals: {
              given: number;

              received: number;
            };

            points: number;
          };

          matches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          homeMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;

          awayMatches?: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;
            };

            result: 'W' | 'L' | 'D';

            homeaway: 'home' | 'away';

            matchId: string;

            score: string;
          }>;
        }>;

        players?: Array<{
          _id: string;

          teamId: string;

          name: string;

          stats: {
            match_starts?: number;

            match_appearances?: number;

            goals?: number;

            assists?: number;

            yellow_cards?: number;

            red_cards?: number;

            substitutions?: number;

            own_goals?: number;
          };
        }>;
      };

      matches_count?: number;

      matchesCountByTeamId?: {};

      dateFrom: string;

      dateTo: string;
    }>;

    signup?: boolean;

    aggregatedPlayerStats?: boolean;

    publication?: {};

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;

    interstate?: boolean;
  }> {
    let path = '/match-manager/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetCompetitionPartById
   */
  matchManagerGetCompetitionPartById(
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {
      sport_sector: string;

      discipline?: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};

    teams?: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;
  }> {
    let path = '/match-manager/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetMatchById
   */
  matchManagerGetMatchById(
    competitionId: string,
    partId: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerUpdateMatch
   */
  matchManagerUpdateMatch(
    competitionId: string,
    partId: string,
    matchId: string,
    parameters: {} = {},
    body: {
      scoreComputationDisabled?: boolean;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      score?: Array<number>;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      livestream?: string;

      ticketsUrl?: string;

      detailUrl?: string;

      perex?: string;

      teams?: Array<{
        _id?: string;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      competitionId: string;

      partId: string;

      seasonId?: string;

      roundId?: string;

      nominations?: Array<{
        teamId?: string;

        athletes: Array<{
          sportnetUser: {
            _id: string | null;

            name: string;
          };

          additionalData?: {};
        }>;

        crew?: Array<{
          sportnetUser: {
            _id: string | null;

            name: string | null;
          };

          position: string;
        }>;

        closed?: boolean;
      }>;

      protocol?: {};

      rules?: {
        gender?: string;

        sport_sector?: string;
      };

      closed?: boolean;

      drawId?: number;

      timer?: {};
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetMatchSuitableTeams
   */
  matchManagerGetMatchSuitableTeams(
    matchId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/match-manager/matches/{matchId}/suitable-teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetMatchNominations
   */
  matchManagerGetMatchNominations(
    competitionId: string,
    partId: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerPutMatchNominations
   */
  matchManagerPutMatchNominations(
    competitionId: string,
    partId: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    },
  ): Promise<{}> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetVarEvents
   */
  matchManagerGetVarEvents(
    competitionId: string,
    partId: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    varEvents?: Array<{
      status: string;

      subject?: string;

      detailedSubject?: string;

      verdict?: string;

      datetime: string;

      _id: string;

      description: string;

      authUser: {
        sportnetId?: string;

        displayName?: string;
      };
    }>;
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/varevents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerAddVarEvent
   */
  matchManagerAddVarEvent(
    competitionId: string,
    partId: string,
    matchId: string,
    parameters: {} = {},
    body: {
      status: string;

      subject?: string;

      detailedSubject?: string;

      verdict?: string;

      datetime?: string;
    },
  ): Promise<{
    status: string;

    subject?: string;

    detailedSubject?: string;

    verdict?: string;

    datetime: string;

    _id: string;

    description: string;

    authUser: {
      sportnetId?: string;

      displayName?: string;
    };
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/matches/{matchId}/varevents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#matchManagerGetOrganizationSportExperts
   */
  matchManagerGetOrganizationSportExperts(
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {
      competenceTypes?: Array<string>;
    } = {},
  ): Promise<{
    sportExperts?: Array<{
      sportnetId: string;

      displayName: string;

      familyName?: string;

      birthyear?: string;
    }>;
  }> {
    let path =
      '/match-manager/competitions/{competitionId}/parts/{partId}/teams/{teamId}/sport-experts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['competenceTypes'] !== 'undefined') {
      queryParameters['competenceTypes'] = parameters['competenceTypes'];
    }

    queryParameters['competenceTypes'] = this.transformParameter(
      queryParameters['competenceTypes'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Contentspace Details
   * @method
   * @name CompetitionsApi#publicGetSettings
   */
  publicGetSettings(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    domain?: string;

    homepageSectionIdOrUniqId?: string;
  }> {
    let path = '/public/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitions
   */
  getCompetitions(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
      seasonId?: string;
      q?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    competitions: Array<{
      _id?: string;

      competitionGroupId?: string;

      appSpace?: string;

      name?: string;

      season?: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      publication?: {};
    }>;
  }> {
    let path = '/public/{appSpace}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionGroupById
   */
  getCompetitionGroupById(
    appSpace: string,
    competitionGroupId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    competitions: Array<{
      _id: string;

      __issfId?: number;

      appSpace: string;

      name: string;

      competitionGroupId: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };
    }>;
  }> {
    let path = '/public/{appSpace}/competition-groups/{competitionGroupId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionGroupId}', competitionGroupId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionById
   */
  getCompetitionById(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    __issfId?: number;

    aggregatedPlayerStats?: boolean;

    competitionGroupId: string;

    level?: number;

    parts?: Array<{
      _id: string;

      name: string;

      publicComment?: string;

      dateFrom?: string;

      dateTo?: string;

      rules: {
        sport_sector: string;

        gender?: string;

        category?: string;
      };
    }>;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    homepageSectionIdOrUniqId?: string;

    appSpace: string;

    ownerPPO?: string;

    name: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    publication?: {};

    interstate?: boolean;
  }> {
    let path = '/public/{appSpace}/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionParts
   */
  getCompetitionParts(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    parts: Array<{
      _id: string;

      __issfId?: string;

      name: string;

      publicComment?: string;

      dateFrom: string;

      dateTo: string;

      format?: string;

      rounds?: Array<{
        _id: string;

        name: string;

        dateFrom: string;

        dateTo: string;
      }>;
    }>;
  }> {
    let path = '/public/{appSpace}/competitions/{competitionId}/parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionPartById
   */
  getCompetitionPartById(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {
      roundId?: string;
      omitResultsTable?: boolean;
    } = {},
  ): Promise<{
    _id: string;

    name: string;

    settings?: {};

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    dateFrom: string;

    dateTo: string;
  }> {
    let path = '/public/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['omitResultsTable'] !== 'undefined') {
      queryParameters['omitResultsTable'] = parameters['omitResultsTable'];
    }

    queryParameters['omitResultsTable'] = this.transformParameter(
      queryParameters['omitResultsTable'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionPartMatches
   */
  getCompetitionPartMatches(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {
      limit?: number;
      offset?: number;
      roundId?: string;
      dateBefore?: string;
      dateAfter?: string;
      withDate?: boolean;
      withTeams?: boolean;
      closed?: boolean;
      sorter?:
        | 'dateFromAsc'
        | 'dateFromDesc'
        | 'roundDateFromAsc'
        | 'roundDateFromDesc';
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;
  }> {
    let path =
      '/public/{appSpace}/competitions/{competitionId}/parts/{partId}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['dateBefore'] !== 'undefined') {
      queryParameters['dateBefore'] = parameters['dateBefore'];
    }

    queryParameters['dateBefore'] = this.transformParameter(
      queryParameters['dateBefore'],
    );

    if (typeof parameters['dateAfter'] !== 'undefined') {
      queryParameters['dateAfter'] = parameters['dateAfter'];
    }

    queryParameters['dateAfter'] = this.transformParameter(
      queryParameters['dateAfter'],
    );

    if (typeof parameters['withDate'] !== 'undefined') {
      queryParameters['withDate'] = parameters['withDate'];
    }

    queryParameters['withDate'] = this.transformParameter(
      queryParameters['withDate'],
    );

    if (typeof parameters['withTeams'] !== 'undefined') {
      queryParameters['withTeams'] = parameters['withTeams'];
    }

    queryParameters['withTeams'] = this.transformParameter(
      queryParameters['withTeams'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionMatches
   */
  getCompetitionMatches(
    appSpace: string,
    competitionId: string,
    parameters: {
      limit?: number;
      offset?: number;
      closed?: boolean;
      dateBefore?: string;
      dateAfter?: string;
      withTeams?: boolean;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;
  }> {
    let path = '/public/{appSpace}/competitions/{competitionId}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['dateBefore'] !== 'undefined') {
      queryParameters['dateBefore'] = parameters['dateBefore'];
    }

    queryParameters['dateBefore'] = this.transformParameter(
      queryParameters['dateBefore'],
    );

    if (typeof parameters['dateAfter'] !== 'undefined') {
      queryParameters['dateAfter'] = parameters['dateAfter'];
    }

    queryParameters['dateAfter'] = this.transformParameter(
      queryParameters['dateAfter'],
    );

    if (typeof parameters['withTeams'] !== 'undefined') {
      queryParameters['withTeams'] = parameters['withTeams'];
    }

    queryParameters['withTeams'] = this.transformParameter(
      queryParameters['withTeams'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionTeams
   */
  getCompetitionTeams(
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      __issfId?: number;

      name: string;

      displayName: string;

      appSpace: string;

      logo?: {
        media_url?: string;

        public_url?: string;
      };

      organization: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionPartTeams
   */
  getCompetitionPartTeams(
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      __issfId?: number;

      name: string;

      displayName: string;

      appSpace: string;

      logo?: {
        media_url?: string;

        public_url?: string;
      };

      organization: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/parts/{partId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCompetitionPartTeamById
   */
  getCompetitionPartTeamById(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    __issfId?: number;

    name: string;

    displayName: string;

    appSpace: string;

    logo?: {
      media_url?: string;

      public_url?: string;
    };

    organization: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    squad?: {
      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser?: {
          _id: string;

          name: string;
        };

        position?: string;
      }>;
    };
  }> {
    let path =
      '/public/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPublicCompetitionPlayersStats
   */
  getPublicCompetitionPlayersStats(
    competitionId: string,
    parameters: {
      teamId?: string;
    } = {},
  ): Promise<{
    players: Array<{
      match_starts?: number;

      match_appearances?: number;

      goals?: number;

      assists?: number;

      yellow_cards?: number;

      red_cards?: number;

      substitutions?: number;

      own_goals?: number;
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPublicCompetitionPartCurrentResultTable
   */
  getPublicCompetitionPartCurrentResultTable(
    competitionId: string,
    partId: string,
    parameters: {
      roundId?: string;
    } = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/parts/{partId}/results';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPublicCompetitionPartCurrentPlayersStats
   */
  getPublicCompetitionPartCurrentPlayersStats(
    competitionId: string,
    partId: string,
    parameters: {
      roundId?: string;
      teamId?: string;
    } = {},
  ): Promise<{
    players: Array<{
      match_starts?: number;

      match_appearances?: number;

      goals?: number;

      assists?: number;

      yellow_cards?: number;

      red_cards?: number;

      substitutions?: number;

      own_goals?: number;
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/parts/{partId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getMatchResultsTable
   */
  getMatchResultsTable(
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;
  }> {
    let path = '/matches/{matchId}/results';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getMatchVarEvents
   */
  getMatchVarEvents(
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    varEvents: Array<{
      status: string;

      subject?: string;

      detailedSubject?: string;

      verdict?: string;

      datetime: string;

      _id: string;

      description: string;

      authUser: {
        sportnetId?: string;

        displayName?: string;
      };
    }>;
  }> {
    let path = '/matches/{matchId}/varevents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPlayerStats
   */
  getPlayerStats(
    appSpace: string,
    sportnetId: string,
    parameters: {
      seasonId?: string;
      competitionGroupId?: string;
      teamId?: string;
    } = {},
  ): Promise<{
    sportnetUser: {
      _id: string;

      name: string;
    };

    playerData?: {};

    teamsHistory: Array<{
      teamId: string;

      firstPlayedOn: {
        _id: string;

        score?: Array<number>;

        sportGround: {
          appSpace: string;

          city?: string;

          country?: string;

          image?: {
            public_url: string;

            media_url: string;
          };

          name: string;

          number?: string;

          perex?: string;

          sport?: string;

          sportGroundType?: string;

          sportObjectName: string;

          street?: string;

          zip?: string;

          _id: string;
        };

        startDate: string;

        competition: {
          _id: string;

          name: string;
        };

        competitionPart: {
          _id: string;

          name: string;
        };

        season: {
          _id: string;

          name: string;
        };

        teams: Array<{
          _id: string;

          name: string;

          displayName?: string;

          appSpace: string;

          additionalProperties?: {};

          organization?: {
            _id: string;

            organization_id: string;

            name: string;

            country?: string;
          };
        }>;
      };

      lastPlayedOn: {
        _id: string;

        score?: Array<number>;

        sportGround: {
          appSpace: string;

          city?: string;

          country?: string;

          image?: {
            public_url: string;

            media_url: string;
          };

          name: string;

          number?: string;

          perex?: string;

          sport?: string;

          sportGroundType?: string;

          sportObjectName: string;

          street?: string;

          zip?: string;

          _id: string;
        };

        startDate: string;

        competition: {
          _id: string;

          name: string;
        };

        competitionPart: {
          _id: string;

          name: string;
        };

        season: {
          _id: string;

          name: string;
        };

        teams: Array<{
          _id: string;

          name: string;

          displayName?: string;

          appSpace: string;

          additionalProperties?: {};

          organization?: {
            _id: string;

            organization_id: string;

            name: string;

            country?: string;
          };
        }>;
      };
    }>;

    summary: {
      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    };

    byCompetition: Array<{
      _id: string;

      name: string;

      season: {
        _id: string;

        name: string;
      };

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;

    byCompetitionGroup: Array<{
      _id: string;

      name: string;

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;
  }> {
    let path = '/public/{appSpace}/players/{sportnetId}/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getCrewMemberStats
   */
  getCrewMemberStats(
    appSpace: string,
    sportnetId: string,
    parameters: {
      seasonId?: string;
      competitionGroupId?: string;
    } = {},
  ): Promise<{
    teamsHistory: Array<{
      teamId: string;

      firstCouchedOn: {
        _id: string;

        score?: Array<number>;

        sportGround: {
          appSpace: string;

          city?: string;

          country?: string;

          image?: {
            public_url: string;

            media_url: string;
          };

          name: string;

          number?: string;

          perex?: string;

          sport?: string;

          sportGroundType?: string;

          sportObjectName: string;

          street?: string;

          zip?: string;

          _id: string;
        };

        startDate: string;

        competition: {
          _id: string;

          name: string;
        };

        competitionPart: {
          _id: string;

          name: string;
        };

        season: {
          _id: string;

          name: string;
        };

        teams: Array<{
          _id: string;

          name: string;

          displayName?: string;

          appSpace: string;

          additionalProperties?: {};

          organization?: {
            _id: string;

            organization_id: string;

            name: string;

            country?: string;
          };
        }>;
      };

      lastCouchedOn: {
        _id: string;

        score?: Array<number>;

        sportGround: {
          appSpace: string;

          city?: string;

          country?: string;

          image?: {
            public_url: string;

            media_url: string;
          };

          name: string;

          number?: string;

          perex?: string;

          sport?: string;

          sportGroundType?: string;

          sportObjectName: string;

          street?: string;

          zip?: string;

          _id: string;
        };

        startDate: string;

        competition: {
          _id: string;

          name: string;
        };

        competitionPart: {
          _id: string;

          name: string;
        };

        season: {
          _id: string;

          name: string;
        };

        teams: Array<{
          _id: string;

          name: string;

          displayName?: string;

          appSpace: string;

          additionalProperties?: {};

          organization?: {
            _id: string;

            organization_id: string;

            name: string;

            country?: string;
          };
        }>;
      };
    }>;
  }> {
    let path = '/public/{appSpace}/crewMembers/{sportnetId}/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getAllTeams
   */
  getAllTeams(
    parameters: {
      q?: string;
      seasonIds?: Array<string>;
    } = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      appSpace: string;

      name: string;

      displayName: string;

      gender: string;

      sport_sector: string;
    }>;
  }> {
    let path = '/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['seasonIds'] !== 'undefined') {
      queryParameters['season_ids'] = parameters['seasonIds'];
    }

    queryParameters['season_ids'] = this.transformParameter(
      queryParameters['season_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getTeams
   */
  getTeams(
    appSpace: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    teams: Array<{
      _id: string;

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      appSpace: string;

      name: string;

      displayName: string;

      gender: string;

      sport_sector: string;

      season: {
        _id?: string;

        appSpace?: string;

        name?: string;

        dateFrom?: string;

        dateTo?: string;

        sport?: string;
      };
    }>;
  }> {
    let path = '/public/{appSpace}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getTeam
   */
  getTeam(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    gender?: string;

    sport_sector: string;

    season: {
      _id?: string;

      appSpace?: string;

      name?: string;

      dateFrom?: string;

      dateTo?: string;

      sport?: string;
    };
  }> {
    let path = '/public/{appSpace}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Parameter date can be in various formats. If it is only year, system looks for valid squads in whole year.
If it is concrete date - system looks for valid squad to this date.

Every squad has validity from.

    * @method
    * @name CompetitionsApi#publicGetTeamSquad
    */
  publicGetTeamSquad(
    appSpace: string,
    teamId: string,
    parameters: {
      date?: string;
      competitionId?: string;
      partId?: string;
    } = {},
  ): Promise<{
    team: {
      _id: string;

      name: string;

      displayName?: string;

      gender?: string;

      detailUrl?: string;

      sport_sector?: string;
    };

    athletes: Array<{
      additionalProperties?: {};

      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path = '/public/{appSpace}/teams/{teamId}/squad';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    if (typeof parameters['date'] !== 'undefined') {
      queryParameters['date'] = parameters['date'];
    }

    queryParameters['date'] = this.transformParameter(queryParameters['date']);

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    if (typeof parameters['partId'] !== 'undefined') {
      queryParameters['partId'] = parameters['partId'];
    }

    queryParameters['partId'] = this.transformParameter(
      queryParameters['partId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Parameter date can be in various formats. If it is only year, system looks for valid members in whole year.
If it is concrete date - system looks for valid members to this date.

Every members has validity from.

    * @method
    * @name CompetitionsApi#publicGetTeamMembers
    */
  publicGetTeamMembers(
    appSpace: string,
    teamId: string,
    parameters: {
      date?: string;
    } = {},
  ): Promise<{
    _id?: string;

    title: string;

    validFrom: string;

    crew: Array<{
      position: string;

      sportnetUser: {
        _id: string;

        name: string;
      };
    }>;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    date?: string;
  }> {
    let path = '/public/{appSpace}/teams/{teamId}/members';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    if (typeof parameters['date'] !== 'undefined') {
      queryParameters['date'] = parameters['date'];
    }

    queryParameters['date'] = this.transformParameter(queryParameters['date']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getTeamCompetitions
   */
  getTeamCompetitions(
    appSpace: string,
    teamId: string,
    parameters: {
      seasonId?: string;
    } = {},
  ): Promise<{
    competitions: Array<{
      _id?: string;

      competitionGroupId?: string;

      appSpace?: string;

      name?: string;

      season?: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      publication?: {};
    }>;
  }> {
    let path = '/public/{appSpace}/teams/{teamId}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getTeamCompetition
   */
  getTeamCompetition(
    appSpace: string,
    teamId: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    competitionGroupId: string;

    appSpace: string;

    name: string;

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    publication?: {};

    players: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };
  }> {
    let path = '/public/{appSpace}/teams/{teamId}/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getTeamCompetitionMatches
   */
  getTeamCompetitionMatches(
    appSpace: string,
    teamIds: Array<string>,
    competitionId: string,
    parameters: {
      limit?: number;
      offset?: number;
      closed?: boolean;
      dateBefore?: string;
      dateAfter?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      perex?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      appSpace: string;

      drawId?: number;

      teams?: Array<{
        _id: string;

        appSpace: string;

        organization?: {
          _id: string;

          organization_id: string;

          organization_name?: string;

          name: string;

          country?: string;

          logo_public_url?: string;
        };

        logo?: {
          media_url: string;

          public_url: string;
        };

        smarttags?: Array<{
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        }>;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        filter?: {
          dateFrom?: string;

          dateTo?: string;
        };

        athletes?: Array<{
          sportnetUser: {
            _id: string;

            name: string;
          };

          additionalData?: {};
        }>;
      }>;

      startDate: string;

      sportGround?: {
        _id: string;

        name: string;

        displayName?: string;

        sportGroundType: string;

        additionalData?: {};
      };

      settings?: {
        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };

        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      rules?: {};

      contumation?: {
        isContumated: boolean;

        advantage: null | string;

        keepScore: boolean;
      };

      protocol?: {};

      liveState?: {
        ongoing: boolean;

        seconds: number;

        phase: string;

        toggleDate: string;
      };

      timer?: {};

      detailUrl?: string;
    }>;
  }> {
    let path =
      '/public/{appSpace}/teams/{teamIds}/competitions/{competitionId}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamIds}', teamIds.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['dateBefore'] !== 'undefined') {
      queryParameters['dateBefore'] = parameters['dateBefore'];
    }

    queryParameters['dateBefore'] = this.transformParameter(
      queryParameters['dateBefore'],
    );

    if (typeof parameters['dateAfter'] !== 'undefined') {
      queryParameters['dateAfter'] = parameters['dateAfter'];
    }

    queryParameters['dateAfter'] = this.transformParameter(
      queryParameters['dateAfter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getSeasons
   */
  getSeasons(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    }>;
  }> {
    let path = '/public/{appSpace}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getClubPlayedSeasons
   */
  getClubPlayedSeasons(
    appSpace: string,
    parameters: {
      playerId?: string;
    } = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    }>;
  }> {
    let path = '/public/{appSpace}/played-seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['playerId'] !== 'undefined') {
      queryParameters['playerId'] = parameters['playerId'];
    }

    queryParameters['playerId'] = this.transformParameter(
      queryParameters['playerId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Club Played Years
   * @method
   * @name CompetitionsApi#getDistinctClubPlayedYears
   */
  getDistinctClubPlayedYears(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    data: Array<{
      year: number;

      count: number;
    }>;
  }> {
    let path = '/public/{appSpace}/played-years';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get Team Played Years
   * @method
   * @name CompetitionsApi#getDistinctTeamPlayedYears
   */
  getDistinctTeamPlayedYears(
    appSpace: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    data: Array<{
      year: number;

      count: number;
    }>;
  }> {
    let path = '/public/{appSpace}/teams/{teamId}/played-years';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getMatch
   */
  getMatch(
    matchId: string,
    parameters: {
      noCache?: number;
    } = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    __issfId?: number;

    contumationScore?: Array<number>;

    score?: Array<number>;

    scoreIsComputed?: boolean;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['noCache'] !== 'undefined') {
      queryParameters['noCache'] = parameters['noCache'];
    }

    queryParameters['noCache'] = this.transformParameter(
      queryParameters['noCache'],
    );

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getRecordsBySport
   */
  getRecordsBySport(
    sport: string,
    parameters: {
      gender?: string;
      ageCategoryId?: string;
      disciplineName?: string;
      disciplineParameters?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      gender: string;

      sport: string;

      records: Array<{
        place: string;

        date: string;

        record: {
          type: string;

          value: string;
        };

        holders: Array<{
          _id: string;

          name: string;
        }>;

        club: {
          _id: string;

          name: string;
        };
      }>;

      ageCategory: {
        _id: string;

        name: string;
      };

      discipline: {
        _id: string;

        name: string;

        parameters: Array<{
          _id: string;

          name: string;

          value: string;
        }>;
      };
    }>;
  }> {
    let path = '/records/{sport}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['gender'] !== 'undefined') {
      queryParameters['gender'] = parameters['gender'];
    }

    queryParameters['gender'] = this.transformParameter(
      queryParameters['gender'],
    );

    if (typeof parameters['ageCategoryId'] !== 'undefined') {
      queryParameters['ageCategoryId'] = parameters['ageCategoryId'];
    }

    queryParameters['ageCategoryId'] = this.transformParameter(
      queryParameters['ageCategoryId'],
    );

    if (typeof parameters['disciplineName'] !== 'undefined') {
      queryParameters['disciplineName'] = parameters['disciplineName'];
    }

    queryParameters['disciplineName'] = this.transformParameter(
      queryParameters['disciplineName'],
    );

    if (typeof parameters['disciplineParameters'] !== 'undefined') {
      queryParameters['disciplineParameters'] =
        parameters['disciplineParameters'];
    }

    queryParameters['disciplineParameters'] = this.transformParameter(
      queryParameters['disciplineParameters'],
    );

    path = path.replace('{sport}', sport.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getSettingById
   */
  getSettingById(settingId: string, parameters: {} = {}): Promise<{}> {
    let path = '/settings/{settingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{settingId}', settingId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getSettingBySportSector
   */
  getSettingBySportSector(
    settingId: string,
    sectorId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      label: string;
    }>;
  }> {
    let path = '/settings/{settingId}/sector/{sectorId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{settingId}', settingId.toString());

    path = path.replace('{sectorId}', sectorId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getAllCompetitionGroups
   */
  getAllCompetitionGroups(
    parameters: {
      appSpaces?: Array<string>;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      name: string;

      appSpace: string;
    }>;
  }> {
    let path = '/competition-groups';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['appSpaces'] !== 'undefined') {
      queryParameters['appSpaces'] = parameters['appSpaces'];
    }

    queryParameters['appSpaces'] = this.transformParameter(
      queryParameters['appSpaces'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getAllCompetitions
   */
  getAllCompetitions(
    parameters: {
      withPartsProjection?: boolean;
      limit?: string;
      offset?: string;
      q?: string;
      appSpace?: Array<string>;
      ownerPpo?: Array<string>;
      format?: string;
      type?: string;
      seasonName?: string;
      seasonNames?: Array<string>;
      date?: string;
      teamIds?: Array<string>;
      clubIds?: Array<string>;
    } = {},
  ): Promise<{
    competitions: Array<{
      _id: string;

      __issfId?: number;

      competitionGroupId: string;

      sortvalue?: number;

      level?: number;

      appSpace: string;

      sport: string;

      name: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      parts?: Array<{
        _id: string;

        name: string;

        rules?: {
          sport_sector?: string;

          gender?: string;

          category?: string;
        };

        settings?: {};

        rounds?: Array<{
          _id: string;

          name: string;
        }>;
      }>;
    }>;
  }> {
    let path = '/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['withPartsProjection'] !== 'undefined') {
      queryParameters['withPartsProjection'] =
        parameters['withPartsProjection'];
    }

    queryParameters['withPartsProjection'] = this.transformParameter(
      queryParameters['withPartsProjection'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['ownerPpo'] !== 'undefined') {
      queryParameters['ownerPPO'] = parameters['ownerPpo'];
    }

    queryParameters['ownerPPO'] = this.transformParameter(
      queryParameters['ownerPPO'],
    );

    if (typeof parameters['format'] !== 'undefined') {
      queryParameters['format'] = parameters['format'];
    }

    queryParameters['format'] = this.transformParameter(
      queryParameters['format'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['seasonName'] !== 'undefined') {
      queryParameters['seasonName'] = parameters['seasonName'];
    }

    queryParameters['seasonName'] = this.transformParameter(
      queryParameters['seasonName'],
    );

    if (typeof parameters['seasonNames'] !== 'undefined') {
      queryParameters['seasonNames'] = parameters['seasonNames'];
    }

    queryParameters['seasonNames'] = this.transformParameter(
      queryParameters['seasonNames'],
    );

    if (typeof parameters['date'] !== 'undefined') {
      queryParameters['date'] = parameters['date'];
    }

    queryParameters['date'] = this.transformParameter(queryParameters['date']);

    if (typeof parameters['teamIds'] !== 'undefined') {
      queryParameters['teamIds'] = parameters['teamIds'];
    }

    queryParameters['teamIds'] = this.transformParameter(
      queryParameters['teamIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['clubIds'] !== 'undefined') {
      queryParameters['clubIds'] = parameters['clubIds'];
    }

    queryParameters['clubIds'] = this.transformParameter(
      queryParameters['clubIds'],
      'joinUsingPipes',
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPublicCompetitionPartById
   */
  getPublicCompetitionPartById(
    competitionId: string,
    partId: string,
    parameters: {
      roundId?: string;
    } = {},
  ): Promise<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    dateFrom: string;

    dateTo: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };
  }> {
    let path = '/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getMatches
   */
  getMatches(
    parameters: {
      noCache?: number;
      playerAppSpace?: string;
      playerId?: string;
      competitionGroupId?: string;
      competitionId?: string;
      partId?: string;
      roundId?: string;
      dateFrom?: string;
      dateTo?: string;
      withDate?: boolean;
      withTeams?: boolean;
      closed?: boolean;
      teamId?: string;
      seasonName?: string;
      ids?: Array<string>;
      excludeIds?: Array<string>;
      teamAppSpaces?: Array<string>;
      teamIds?: Array<string>;
      allTeamIds?: Array<string>;
      appSpace?: string;
      smarttagsAppSpace?: string;
      smarttags?: Array<string>;
      rulesSportSector?: string;
      managerRoles?: 'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR';
      sorter?:
        | 'dateFromAsc'
        | 'dateFromDesc'
        | 'roundDateFromAsc'
        | 'roundDateFromDesc';
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    nextOffset?: number | null;

    total?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        shortName?: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        displayName?: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };

      detailUrl?: string;

      television_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;

      internet_broadcasters?: Array<{
        _id: string;

        label: string;
      }>;
    }>;
  }> {
    let path = '/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['noCache'] !== 'undefined') {
      queryParameters['noCache'] = parameters['noCache'];
    }

    queryParameters['noCache'] = this.transformParameter(
      queryParameters['noCache'],
    );

    if (typeof parameters['playerAppSpace'] !== 'undefined') {
      queryParameters['playerAppSpace'] = parameters['playerAppSpace'];
    }

    queryParameters['playerAppSpace'] = this.transformParameter(
      queryParameters['playerAppSpace'],
    );

    if (typeof parameters['playerId'] !== 'undefined') {
      queryParameters['playerId'] = parameters['playerId'];
    }

    queryParameters['playerId'] = this.transformParameter(
      queryParameters['playerId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    if (typeof parameters['partId'] !== 'undefined') {
      queryParameters['partId'] = parameters['partId'];
    }

    queryParameters['partId'] = this.transformParameter(
      queryParameters['partId'],
    );

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['withDate'] !== 'undefined') {
      queryParameters['withDate'] = parameters['withDate'];
    }

    queryParameters['withDate'] = this.transformParameter(
      queryParameters['withDate'],
    );

    if (typeof parameters['withTeams'] !== 'undefined') {
      queryParameters['withTeams'] = parameters['withTeams'];
    }

    queryParameters['withTeams'] = this.transformParameter(
      queryParameters['withTeams'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    if (typeof parameters['seasonName'] !== 'undefined') {
      queryParameters['seasonName'] = parameters['seasonName'];
    }

    queryParameters['seasonName'] = this.transformParameter(
      queryParameters['seasonName'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['excludeIds'] = parameters['excludeIds'];
    }

    queryParameters['excludeIds'] = this.transformParameter(
      queryParameters['excludeIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamAppSpaces'] !== 'undefined') {
      queryParameters['teamAppSpaces'] = parameters['teamAppSpaces'];
    }

    queryParameters['teamAppSpaces'] = this.transformParameter(
      queryParameters['teamAppSpaces'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamIds'] !== 'undefined') {
      queryParameters['teamIds'] = parameters['teamIds'];
    }

    queryParameters['teamIds'] = this.transformParameter(
      queryParameters['teamIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['allTeamIds'] !== 'undefined') {
      queryParameters['allTeamIds'] = parameters['allTeamIds'];
    }

    queryParameters['allTeamIds'] = this.transformParameter(
      queryParameters['allTeamIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['smarttagsAppSpace'] !== 'undefined') {
      queryParameters['smarttagsAppSpace'] = parameters['smarttagsAppSpace'];
    }

    queryParameters['smarttagsAppSpace'] = this.transformParameter(
      queryParameters['smarttagsAppSpace'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['rulesSportSector'] !== 'undefined') {
      queryParameters['rulesSportSector'] = parameters['rulesSportSector'];
    }

    queryParameters['rulesSportSector'] = this.transformParameter(
      queryParameters['rulesSportSector'],
    );

    if (typeof parameters['managerRoles'] !== 'undefined') {
      queryParameters['managerRoles'] = parameters['managerRoles'];
    }

    queryParameters['managerRoles'] = this.transformParameter(
      queryParameters['managerRoles'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getH2HMatches
   */
  getH2HMatches(
    parameters: {
      competitionGroupId?: string;
      dateFrom?: string;
      dateTo?: string;
      closed?: boolean;
      excludeIds?: Array<string>;
      teamAppSpaces?: Array<string>;
      limit?: number;
    } = {},
  ): Promise<{
    firstTeamMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };
    }>;

    secondTeamMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };
    }>;

    commonMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };
    }>;
  }> {
    let path = '/h2h-matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['excludeIds'] = parameters['excludeIds'];
    }

    queryParameters['excludeIds'] = this.transformParameter(
      queryParameters['excludeIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamAppSpaces'] !== 'undefined') {
      queryParameters['teamAppSpaces'] = parameters['teamAppSpaces'];
    }

    queryParameters['teamAppSpaces'] = this.transformParameter(
      queryParameters['teamAppSpaces'],
      'joinUsingPipes',
    );

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getLastRoundMatches
   */
  getLastRoundMatches(
    competitionId: string,
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    nextOffset?: number | null;

    total?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: null | string;
      };
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/last-round-matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getH2HStats
   */
  getH2HStats(
    teamIds: Array<string>,
    parameters: {} = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;
  }> {
    let path = '/public/h2h/{teamIds}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{teamIds}', teamIds.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPlayerMatches
   */
  getPlayerMatches(
    appSpace: string,
    sportnetId: string,
    parameters: {
      limit?: number;
      offset?: number;
      competitionId?: string;
      competitionGroupId?: string;
      partId?: string;
      roundId?: string;
      dateFrom?: string;
      dateTo?: string;
      closed?: boolean;
      teamId?: string;
      ids?: Array<string>;
      sorter?:
        | 'dateFromAsc'
        | 'dateFromDesc'
        | 'roundDateFromAsc'
        | 'roundDateFromDesc';
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __externalId?: string;

      drawId?: number;

      contumationScore?: Array<number>;

      score?: Array<number>;

      scoreIsComputed?: boolean;

      penaltiesScore?: Array<number>;

      scoreByPhases?: Array<Array<number>>;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id?: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      detailUrl?: string;
    }>;
  }> {
    let path = '/player-matches/ppo/{appSpace}/player/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['partId'] !== 'undefined') {
      queryParameters['partId'] = parameters['partId'];
    }

    queryParameters['partId'] = this.transformParameter(
      queryParameters['partId'],
    );

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getLatestTeamAppSpaceResultsTable
   */
  getLatestTeamAppSpaceResultsTable(
    appSpace: string,
    parameters: {
      ownerPpo?: string;
    } = {},
  ): Promise<{
    _id: string;

    competitionId: string;

    appSpace: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    dateFrom: string;

    dateTo: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };
  }> {
    let path = '/team-competitions/{appSpace}/latest-resultsTable';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['ownerPpo'] !== 'undefined') {
      queryParameters['ownerPPO'] = parameters['ownerPpo'];
    }

    queryParameters['ownerPPO'] = this.transformParameter(
      queryParameters['ownerPPO'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getClubCompetitions
   */
  getClubCompetitions(
    appSpace: string,
    parameters: {
      ownerPpo?: Array<string>;
      seasonName?: string;
    } = {},
  ): Promise<{
    competitions: Array<{
      _id: string;

      __issfId?: number;

      competitionGroupId: string;

      appSpace: string;

      name: string;

      parts: Array<{
        _id: string;

        name: string;

        rules: {
          sport_sector: string;

          gender: string;

          category?: string;
        };

        teams: Array<{
          _id: string;

          name: string;

          appSpace: string;

          organization?: {
            _id: string;

            organization_id: string;

            organization_name?: string;

            name: string;

            country?: string;

            logo_public_url?: string;
          };
        }>;
      }>;

      season: {
        _id: string;

        name: string;
      };
    }>;
  }> {
    let path = '/club/{appSpace}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['ownerPpo'] !== 'undefined') {
      queryParameters['ownerPPO'] = parameters['ownerPpo'];
    }

    queryParameters['ownerPPO'] = this.transformParameter(
      queryParameters['ownerPPO'],
    );

    if (typeof parameters['seasonName'] !== 'undefined') {
      queryParameters['seasonName'] = parameters['seasonName'];
    }

    queryParameters['seasonName'] = this.transformParameter(
      queryParameters['seasonName'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getUnionSeasons
   */
  getUnionSeasons(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      name: string;

      sport: string;

      appSpace: string;

      dateFrom: string;

      dateTo: string;
    }>;
  }> {
    let path = '/union/{appSpace}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPlayerSummaryStatistics
   */
  getPlayerSummaryStatistics(
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      stats: {};

      seasonName: string;
    }>;
  }> {
    let path = '/player/{sportnetId}/statistics';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getHobbySportGrounds
   */
  getHobbySportGrounds(
    appSpace: string,
    parameters: {
      q?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/hobby/{appSpace}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#createHobbySportGround
   */
  createHobbySportGround(
    appSpace: string,
    parameters: {} = {},
    body: {
      sport: string;

      street: string;

      number?: string;

      zip: string;

      city: string;

      country: string;

      geo: {
        lat: number;

        lng: number;
      };
    },
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/hobby/{appSpace}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getHobbySportGroundById
   */
  getHobbySportGroundById(
    appSpace: string,
    sportGroundId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    displayName?: string;

    sportGroundType: string;

    additionalData?: {};
  }> {
    let path = '/hobby/{appSpace}/sportgrounds/{sportGroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportGroundId}', sportGroundId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#createHobbyMatch
   */
  createHobbyMatch(
    appSpace: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround: {
        _id: string;
      };

      competitionId: string;

      partId: string;

      rules?: {
        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/hobby/{appSpace}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getHobbyMatchById
   */
  getHobbyMatchById(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/hobby/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#updateHobbyMatch
   */
  updateHobbyMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround?: {
        _id: string;
      };

      competitionId: string;

      partId: string;

      protocol?: {};

      rules?: {
        sport_sector?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      settings?: {
        players: {
          field: {
            max: number;
          };

          substitutes: {
            max?: number;
          };
        };
      };

      closed?: boolean;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/hobby/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#deleteHobbyMatch
   */
  deleteHobbyMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/hobby/{appSpace}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#copyHobbyMatch
   */
  copyHobbyMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround?: {
        _id: string;
      };

      competitionId: string;

      partId: string;

      protocol?: {};

      rules?: {
        sport_sector?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      settings?: {
        players: {
          field: {
            max: number;
          };

          substitutes: {
            max?: number;
          };
        };
      };

      closed?: boolean;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/hobby/{appSpace}/matches/{matchId}/copy';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getHobbyMatchNomination
   */
  getHobbyMatchNomination(
    appSpace: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }> {
    let path = '/hobby/{appSpace}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#updateHobbyMatchNomination
   */
  updateHobbyMatchNomination(
    appSpace: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;

      name?: string;
    },
  ): Promise<{}> {
    let path = '/hobby/{appSpace}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyCreateCompetitionPart
   */
  hobbyCreateCompetitionPart(
    appSpace: string,
    competitionId: string,
    parameters: {} = {},
    body: {
      name: string;

      rules?: {
        sport_sector?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      dateFrom: string;

      dateTo: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    rules?: {
      sport_sector: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/hobby/{appSpace}/competitions/{competitionId}/parts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyGetCompetitionPart
   */
  hobbyGetCompetitionPart(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    rules?: {
      sport_sector: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyUpdateCompetitionPart
   */
  hobbyUpdateCompetitionPart(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      name?: string;

      rules?: {
        sport_sector: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      dateFrom?: string;

      dateTo?: string;
    },
  ): Promise<{
    _id: string;

    name: string;

    rules?: {
      sport_sector: string;

      gender?: string;

      bornDateFrom?: string;

      bornDateTo?: string;
    };

    dateFrom: string;

    dateTo: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};
  }> {
    let path = '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyDeleteCompetitionPart
   */
  hobbyDeleteCompetitionPart(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyGetCompetitionPartCurrentResultTable
   */
  hobbyGetCompetitionPartCurrentResultTable(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;

    players?: Array<{
      _id: string;

      teamId: string;

      name: string;

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;
  }> {
    let path =
      '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}/results';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyCreateTeam
   */
  hobbyCreateTeam(
    appSpace: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      logo?: {
        media_url: string;

        public_url: string;
      };

      name: string;

      displayName: string;

      shortName?: string;

      gender?: string;

      sport_sector: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      detailUrl?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#hobbyRemoveCompetitionPartSignedUpTeam
   */
  hobbyRemoveCompetitionPartSignedUpTeam(
    appSpace: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path =
      '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#createHobbyTournamentMatch
   */
  createHobbyTournamentMatch(
    appSpace: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround: {
        _id: string;
      };

      competitionId: string;

      partId: string;

      rules?: {
        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      teams?: Array<{
        _id: string;
      }>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#updateHobbyTournamentMatch
   */
  updateHobbyTournamentMatch(
    appSpace: string,
    matchId: string,
    parameters: {} = {},
    body: {
      startDate: string;

      sportGround?: {
        _id: string;
      };

      competitionId: string;

      partId: string;

      protocol?: {};

      rules?: {
        sport_sector?: string;

        gender?: string;

        bornDateFrom?: string;

        bornDateTo?: string;
      };

      settings?: {
        players: {
          field: {
            max: number;
          };

          substitutes: {
            max?: number;
          };
        };
      };

      closed?: boolean;

      teams?: Array<{
        _id: string;
      }>;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    scoreComputationDisabled?: boolean;

    score?: Array<number>;

    penaltiesScore?: Array<number>;

    scoreByPhases?: Array<Array<number>>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path =
      '/hobby/{appSpace}/competitions/{competitionId}/parts/{partId}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetSeasons
   */
  DSGGetSeasons(
    project: string,
    parameters: {} = {},
  ): Promise<{
    seasons: Array<{
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    }>;
  }> {
    let path = '/grassroots/{project}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGCreateSeason
   */
  DSGCreateSeason(
    project: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  }> {
    let path = '/grassroots/{project}/seasons';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetCompetitions
   */
  DSGGetCompetitions(
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
      seasonIds?: Array<string>;
    } = {},
  ): Promise<{
    competitions: Array<{
      _id: string;

      competitionGroupId: string;

      homepageSectionIdOrUniqId?: string;

      appSpace: string;

      ownerPPO?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      parts?: Array<{
        _id: string;

        name: string;

        rounds?: Array<{
          _id: string;

          name: string;

          dateFrom: string;

          dateTo: string;
        }>;

        rules?: {};

        settings?: {};

        signup?: boolean;

        resultsTable?: {
          results: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;

              organization?: {
                _id: string;

                name: string;

                organization_id: string;

                organization_name: string;

                country?: string;
              };

              resignation?: boolean;
            };

            stats: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            homeStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            awayStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            matches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            homeMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            awayMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;
          }>;

          players?: Array<{
            _id: string;

            teamId: string;

            name: string;

            stats: {
              match_starts?: number;

              match_appearances?: number;

              goals?: number;

              assists?: number;

              yellow_cards?: number;

              red_cards?: number;

              substitutions?: number;

              own_goals?: number;
            };
          }>;
        };

        matches_count?: number;

        matchesCountByTeamId?: {};

        dateFrom: string;

        dateTo: string;
      }>;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    }>;
  }> {
    let path = '/grassroots/{project}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['seasonIds'] !== 'undefined') {
      queryParameters['season_ids'] = parameters['seasonIds'];
    }

    queryParameters['season_ids'] = this.transformParameter(
      queryParameters['season_ids'],
    );

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGCreateCompetition
   */
  DSGCreateCompetition(
    project: string,
    parameters: {} = {},
    body: {
      competitionGroupId?: string;

      homepageSectionIdOrUniqId?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    },
  ): Promise<{
    createdCompetition: {
      _id: string;

      competitionGroupId: string;

      homepageSectionIdOrUniqId?: string;

      appSpace: string;

      ownerPPO?: string;

      name: string;

      identifier?: string;

      note?: string;

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      sport: string;

      parts?: Array<{
        _id: string;

        name: string;

        rounds?: Array<{
          _id: string;

          name: string;

          dateFrom: string;

          dateTo: string;
        }>;

        rules?: {};

        settings?: {};

        signup?: boolean;

        resultsTable?: {
          results: Array<{
            team: {
              _id: string;

              name: string;

              displayName: string;

              organization?: {
                _id: string;

                name: string;

                organization_id: string;

                organization_name: string;

                country?: string;
              };

              resignation?: boolean;
            };

            stats: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            homeStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            awayStats?: {
              matches: {
                played: number;

                won: number;

                draw: number;

                lost: number;
              };

              goals: {
                given: number;

                received: number;
              };

              points: number;
            };

            matches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            homeMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;

            awayMatches?: Array<{
              team: {
                _id: string;

                name: string;

                displayName: string;
              };

              result: 'W' | 'L' | 'D';

              homeaway: 'home' | 'away';

              matchId: string;

              score: string;
            }>;
          }>;

          players?: Array<{
            _id: string;

            teamId: string;

            name: string;

            stats: {
              match_starts?: number;

              match_appearances?: number;

              goals?: number;

              assists?: number;

              yellow_cards?: number;

              red_cards?: number;

              substitutions?: number;

              own_goals?: number;
            };
          }>;
        };

        matches_count?: number;

        matchesCountByTeamId?: {};

        dateFrom: string;

        dateTo: string;
      }>;

      signup?: boolean;

      aggregatedPlayerStats?: boolean;

      publication?: {};

      smarttags?: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };

      smarttagsByAppSpace?: Array<{
        appSpace: string;

        smarttags: {
          key: string | number;

          values: Array<{
            key: string | number;

            title?: string | null;
          }>;
        };
      }>;

      teams?: Array<{
        _id: string;

        name: string;

        appSpace: string;
      }>;

      interstate?: boolean;
    };

    createdPart: {
      _id?: string;

      name?: string;

      rules?: {};

      settings?: {};
    };
  }> {
    let path = '/grassroots/{project}/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getPublicDSGMatches
   */
  getPublicDSGMatches(
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
      matchIds?: Array<string>;
    } = {},
  ): Promise<{
    total: number;

    limit: number;

    offset: number;

    items: Array<{
      _id?: string;

      startDate?: string;

      sportGround?: {
        _id?: string;

        name?: string;

        geo?: {
          type?: string;

          coordinates?: Array<number>;
        };
      };
    }>;
  }> {
    let path = '/grassroots/{project}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['matchIds'] !== 'undefined') {
      queryParameters['matchIds'] = parameters['matchIds'];
    }

    queryParameters['matchIds'] = this.transformParameter(
      queryParameters['matchIds'],
    );

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetTeamsAthletesCount
   */
  DSGGetTeamsAthletesCount(
    project: string,
    parameters: {
      seasonId?: string;
    } = {},
  ): Promise<{
    items: Array<{
      _id: string;

      appSpace: string;

      name: string;

      athletesCount: number;
    }>;
  }> {
    let path = '/grassroots/{project}/teams-athletes-count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['seasonId'] !== 'undefined') {
      queryParameters['seasonId'] = parameters['seasonId'];
    }

    queryParameters['seasonId'] = this.transformParameter(
      queryParameters['seasonId'],
    );

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDSGMatches
   */
  getDSGMatches(
    appSpace: string,
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
      matchIds?: Array<string>;
    } = {},
  ): Promise<{
    total: number;

    limit: number;

    offset: number;

    items: Array<{
      _id?: string;

      startDate?: string;

      sportGround?: {
        _id?: string;

        name?: string;

        geo?: {
          type?: string;

          coordinates?: Array<number>;
        };
      };
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['matchIds'] !== 'undefined') {
      queryParameters['matchIds'] = parameters['matchIds'];
    }

    queryParameters['matchIds'] = this.transformParameter(
      queryParameters['matchIds'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#createDSGMatch
   */
  createDSGMatch(
    appSpace: string,
    project: string,
    parameters: {} = {},
    body: {
      teamId: string;

      startDate: string;

      sportGround?: {
        _id: string;
      };

      competitionId: string;

      partId: string;
    },
  ): Promise<{
    _id: string;

    startDate: string;

    season: {
      _id: string;

      name: string;
    };

    sportGround: {
      _id?: string;

      name?: string;

      geo?: {
        type?: string;

        coordinates?: Array<number>;
      };
    };
  }> {
    let path = '/grassroots/{appSpace}:{project}/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDSGPlayerMatches
   */
  getDSGPlayerMatches(
    appSpace: string,
    project: string,
    sportnetId: string,
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    total: number;

    limit: number;

    offset: number;

    items: Array<{
      _id?: string;

      startDate?: string;

      sportGround?: {
        _id?: string;

        name?: string;

        geo?: {
          type?: string;

          coordinates?: Array<number>;
        };
      };
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/player-matches/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDSGMatch
   */
  getDSGMatch(
    appSpace: string,
    project: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    startDate?: string;

    competitionId?: string;

    partId?: string;

    teams?: Array<{
      _id: string;
    }>;

    sportGround?: {
      _id?: string;

      name?: string;

      geo?: {
        type?: string;

        coordinates?: Array<number>;
      };
    };
  }> {
    let path = '/grassroots/{appSpace}:{project}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#updateDSGMatch
   */
  updateDSGMatch(
    appSpace: string,
    project: string,
    matchId: string,
    parameters: {} = {},
    body: {
      startDate?: string;

      sportGround?: {
        _id: string;
      };
    },
  ): Promise<{
    _id?: string;

    startDate?: string;

    sportGround?: {
      _id?: string;

      name?: string;

      geo?: {
        type?: string;

        coordinates?: Array<number>;
      };
    };
  }> {
    let path = '/grassroots/{appSpace}:{project}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#deleteDSGMatch
   */
  deleteDSGMatch(
    appSpace: string,
    project: string,
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    message?: string;
  }> {
    let path = '/grassroots/{appSpace}:{project}/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDSGMatchNomination
   */
  getDSGMatchNomination(
    appSpace: string,
    project: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string | null;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }> {
    let path =
      '/grassroots/{appSpace}:{project}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#updateDSGMatchNomination
   */
  updateDSGMatchNomination(
    appSpace: string,
    project: string,
    matchId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string | null;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    },
  ): Promise<{}> {
    let path =
      '/grassroots/{appSpace}:{project}/matches/{matchId}/nominations/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{matchId}', matchId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDSGSportGrounds
   */
  getDSGSportGrounds(
    appSpace: string,
    project: string,
    parameters: {
      q?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#createDSGSportGround
   */
  createDSGSportGround(
    appSpace: string,
    project: string,
    parameters: {} = {},
    body: {
      sport: string;

      street: string;

      number?: string;

      zip: string;

      city: string;

      country: string;

      geo: {
        lat: number;

        lng: number;
      };
    },
  ): Promise<{
    items?: Array<{
      _id: string;

      name: string;

      displayName?: string;

      sportGroundType: string;

      additionalData?: {};
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/sportgrounds';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetSportGround
   */
  DSGGetSportGround(
    appSpace: string,
    project: string,
    sportgroundId: string,
    parameters: {} = {},
  ): Promise<{
    sport: string;

    street: string;

    number?: string;

    zip: string;

    city: string;

    country: string;

    geo: {
      lat: number;

      lng: number;
    };
  }> {
    let path = '/grassroots/{appSpace}:{project}/sportgrounds/{sportgroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportgroundId}', sportgroundId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGUpdateSportGround
   */
  DSGUpdateSportGround(
    appSpace: string,
    project: string,
    sportgroundId: string,
    parameters: {} = {},
    body: {
      street: string;

      number?: string;

      zip: string;

      city: string;

      country: string;

      geo: {
        lat: number;

        lng: number;
      };
    },
  ): Promise<{
    street: string;

    number?: string;

    zip: string;

    city: string;

    country: string;

    geo: {
      lat: number;

      lng: number;
    };
  }> {
    let path = '/grassroots/{appSpace}:{project}/sportgrounds/{sportgroundId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{sportgroundId}', sportgroundId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetCompetitionTeams
   */
  DSGGetCompetitionTeams(
    project: string,
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    teams: Array<{
      _id: string;

      appSpace: string;

      name: string;

      displayName: string;

      gender?: string;

      sport_sector: string;

      athletes?: Array<{
        playedMinutes: number;

        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/grassroots/{project}/competition-teams/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetTeams
   */
  DSGGetTeams(
    appSpace: string,
    project: string,
    parameters: {
      limit?: number;
      offset?: number;
      competitionId?: string;
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      shortName?: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetTeam
   */
  DSGGetTeam(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGUpdateTeam
   */
  DSGUpdateTeam(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
    body: {
      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name?: string;

      displayName?: string;

      shortName?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      detailUrl?: string;
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path = '/grassroots/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGDeleteTeam
   */
  DSGDeleteTeam(
    appSpace: string,
    project: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/grassroots/{appSpace}:{project}/teams/{teamId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGCreateTeam
   */
  DSGCreateTeam(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      appSpace?: string;

      logo?: {
        media_url: string;

        public_url: string;
      };

      name: string;

      displayName: string;

      shortName?: string;

      gender?: string;

      sport_sector: string;

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      detailUrl?: string;

      filter?: {
        dateFrom?: string;

        dateTo?: string;
      };
    },
  ): Promise<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    shortName?: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    filter?: {
      dateFrom?: string;

      dateTo?: string;
    };

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }> {
    let path =
      '/grassroots/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGGetCompetitionPartTeamSquad
   */
  DSGGetCompetitionPartTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    type?: string;

    sport_sector: string;

    squad?: {
      athletes?: Array<{
        additionalProperties?: {};

        sportnetUser: {
          _id: string;

          name: string;
        };
      }>;
    };
  }> {
    let path =
      '/grassroots/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGAddPlayerToCompetitionPartTeamSquad
   */
  DSGAddPlayerToCompetitionPartTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    parameters: {} = {},
    body: {
      sportnetId?: string;

      additionalData?: {};
    },
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/grassroots/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#DSGRemovePlayerFromCompetitionPartTeamSquad
   */
  DSGRemovePlayerFromCompetitionPartTeamSquad(
    appSpace: string,
    project: string,
    competitionId: string,
    partId: string,
    teamId: string,
    sportnetId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    name?: string;

    squad?: Array<{}>;
  }> {
    let path =
      '/grassroots/{appSpace}:{project}/competitions/{competitionId}/parts/{partId}/teams/{teamId}/players/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{project}', project.toString());

    path = path.replace('{competitionId}', competitionId.toString());

    path = path.replace('{partId}', partId.toString());

    path = path.replace('{teamId}', teamId.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name CompetitionsApi#getDownloaderFile
   */
  getDownloaderFile(downloadId: string, parameters: {} = {}): Promise<void> {
    let path = '/downloader/{downloadId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    path = path.replace('{downloadId}', downloadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new CompetitionsApi();
export { CompetitionsApi };
